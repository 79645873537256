var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabbed-page" },
    [
      _c(
        "b-row",
        { staticClass: "m-0 pt-1" },
        [
          _c("b-col", { staticClass: "pr-0 pl-0 d-flex align-items-center" }, [
            _c("div", { staticClass: "d-flex w-100" }, [
              _c(
                "div",
                {
                  ref: "navToggle",
                  staticClass: "nav-toggle nav-toggle-left py-2 mr-3 my-auto",
                  on: { click: _vm.toggleNavMobile },
                },
                [
                  _c("fa-icon", {
                    staticClass: "toggle-button m-auto",
                    attrs: { icon: "bars", size: "lg" },
                  }),
                ],
                1
              ),
              _c("h1", { staticClass: "title py-3 pr-2 pl-3 my-auto" }, [
                _vm._v("API Management"),
              ]),
              _c(
                "div",
                {
                  ref: "buttonToggle",
                  staticClass: "nav-toggle d-flex my-auto ml-auto",
                  on: { click: _vm.toggleShowButtonsOnMobile },
                },
                [
                  _c("fa-icon", {
                    staticClass: "toggle-button m-auto",
                    attrs: { icon: "ellipsis-v", size: "lg" },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "b-col",
            {
              staticClass:
                "d-flex flex-wrap align-items-center justify-content-end",
              attrs: { cols: "12", md: "auto" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-end flex-wrap w-auto py-2",
                  class: _vm.mobileButtonClass,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex w-auto",
                      class: _vm.mobileButtonClass,
                    },
                    [
                      _vm.selectedTab !== _vm.tabs.doc &&
                      _vm.selectedTab !== _vm.tabs.adminDoc
                        ? _c(
                            "b-button",
                            {
                              staticClass: "header-button",
                              class: [
                                _vm.loading
                                  ? "disabled-button-grey"
                                  : "primary-button",
                                "ml-1",
                                "mr-0",
                              ],
                              attrs: {
                                variant: "primary",
                                "aria-label": "Create API Key button",
                                disabled: _vm.loading,
                              },
                              on: { click: _vm.create },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.createText) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c("hr", { staticClass: "m-0" }),
      _c("div", { staticClass: "content-body" }, [
        _c("div", { staticClass: "side-nav", class: _vm.navClass }, [
          _c(
            "button",
            {
              staticClass: "nav-button",
              class: { selected: _vm.selectedTab === _vm.tabs.key },
              attrs: { "aria-label": "API Keys tab button" },
              on: {
                click: function ($event) {
                  return _vm.tabChange(_vm.tabs.key)
                },
              },
            },
            [
              _c("font-awesome-svg", {
                attrs: {
                  type: "key",
                  fill: _vm.iconFillColor(_vm.tabs.key),
                  width: 25,
                  height: 25,
                },
              }),
              _c("span", { staticClass: "mt-1" }, [_vm._v("API Keys")]),
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass: "nav-button",
              class: { selected: _vm.selectedTab === _vm.tabs.ip },
              attrs: { "aria-label": "Permitted IPs tab button" },
              on: {
                click: function ($event) {
                  return _vm.tabChange(_vm.tabs.ip)
                },
              },
            },
            [
              _c("font-awesome-svg", {
                attrs: {
                  type: "globe",
                  fill: _vm.iconFillColor(_vm.tabs.ip),
                  width: 25,
                  height: 25,
                },
              }),
              _c("span", { staticClass: "mt-1" }, [_vm._v("Permitted IPs")]),
            ],
            1
          ),
          _vm.docsAdminToolsVisible
            ? _c(
                "button",
                {
                  staticClass: "nav-button",
                  class: { selected: _vm.selectedTab === _vm.tabs.doc },
                  attrs: { "aria-label": "Documentation tab button" },
                  on: {
                    click: function ($event) {
                      return _vm.tabChange(_vm.tabs.doc)
                    },
                  },
                },
                [
                  _c("font-awesome-svg", {
                    attrs: {
                      type: "book",
                      fill: _vm.iconFillColor(_vm.tabs.doc),
                      width: 25,
                      height: 25,
                    },
                  }),
                  _c("span", { staticClass: "mt-1" }, [
                    _vm._v("Documentation"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.docsAdminToolsVisible
            ? _c(
                "button",
                {
                  staticClass: "nav-button admin-tools-button",
                  class: { selected: _vm.selectedTab === _vm.tabs.adminDoc },
                  attrs: { "aria-label": "Documentation tab button" },
                  on: {
                    click: function ($event) {
                      return _vm.tabChange(_vm.tabs.adminDoc)
                    },
                  },
                },
                [
                  _c("font-awesome-svg", {
                    attrs: {
                      type: "pencil-paper",
                      fill: _vm.iconFillColor(_vm.tabs.adminDoc),
                      width: 25,
                      height: 25,
                    },
                  }),
                  _c("span", { staticClass: "mt-1" }, [
                    _vm._v("Documentation Admin Tools"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "scrollable-content" },
            [
              !_vm.loading
                ? _c("div", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTab === _vm.tabs.key,
                            expression: "selectedTab === tabs.key",
                          },
                        ],
                      },
                      [
                        _c("account-access-key-list", {
                          attrs: {
                            "account-access-keys": _vm.getAllKeys,
                            "create-access-key": _vm.createAccessKey,
                            "update-access-key": _vm.openUpdateAccessKeyModal,
                            "destroy-access-key": _vm.showDeleteAccessKeyModal,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTab === _vm.tabs.ip,
                            expression: "selectedTab === tabs.ip",
                          },
                        ],
                      },
                      [
                        _c("account-whitelisted-ips-list", {
                          attrs: {
                            "whitelisted-ips": _vm.getAllIps,
                            "create-whitelisted-ip": _vm.createWhitelistedIp,
                            "update-whitelisted-ip":
                              _vm.openUpdateWhitelistedIpModal,
                            "destroy-whitelisted-ip": _vm.showDeleteIpModal,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTab === _vm.tabs.doc,
                            expression: "selectedTab === tabs.doc",
                          },
                        ],
                      },
                      [_c("public-api-documentation")],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectedTab === _vm.tabs.adminDoc,
                            expression: "selectedTab === tabs.adminDoc",
                          },
                        ],
                      },
                      [_c("public-api-docs-admin-tools")],
                      1
                    ),
                  ])
                : _c("ct-centered-spinner"),
            ],
            1
          ),
        ]),
      ]),
      _c("account-access-key-modal", { attrs: { bus: _vm.bus } }),
      _c("account-whitelisted-ip-modal", { attrs: { bus: _vm.bus } }),
      _c("learn-more-button", {
        attrs: { tabindex: "0" },
        on: {
          onclick: _vm.learnMore,
          onKeyDown: function ($event) {
            return _vm.learnMore()
          },
        },
      }),
      _vm._l(_vm.getAllKeys, function (key) {
        return _c(
          "confirmation-modal",
          {
            key: key.id,
            attrs: {
              id: `confirm-delete-key-${key.id}`,
              title: "Delete API Key?",
              "ok-button-text": "Delete",
              "cancel-button-text": "Cancel",
            },
            on: {
              ok: function ($event) {
                return _vm.destroyAccessKey(key.id)
              },
              cancel: function ($event) {
                return _vm.$bvModal.hide(`confirm-delete-key-${key.id}`)
              },
            },
          },
          [_c("div", [_vm._v("This cannot be undone.")])]
        )
      }),
      _vm._l(_vm.getAllIps, function (ip) {
        return _c(
          "confirmation-modal",
          {
            key: ip.id,
            attrs: {
              id: `confirm-delete-ip-${ip.id}`,
              title: "Remove IP?",
              "ok-button-text": "Delete",
              "cancel-button-text": "Cancel",
            },
            on: {
              ok: function ($event) {
                return _vm.destroyIp(ip.id)
              },
              hidden: function ($event) {
                return _vm.$bvModal.hide(`confirm-delete-ip-${ip.id}`)
              },
            },
          },
          [_c("div", [_vm._v("This cannot be undone.")])]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }